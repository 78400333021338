import {Controller} from '@hotwired/stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
    static values = {
        path: String,
    };

    async invalidate() {
        const formData = new FormData();
        await fetch(this.pathValue, {
            method: 'POST',
        })
            .then((response) => {
                if (response.status === 200) {
                    Swal.fire('Success', 'Invalidate cache done', 'success');
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
                console.error(`Failed to invalidate cache`, err);
            });
    }
}
